<template>
  <div
    class="c-container"
    @mousemove="hasActivatedDropdown = true"
  >
    <a
      v-if="!isLoggedIn"
      class="c-trigger"
      href="/job_matching/type_select"
    >
      {{ $t('header.searchMenuRequest') }}
    </a>
    <template v-else>
      <a
        href="/requests?ref=header"
        class="c-trigger"
      >
        単発の仕事を探す
      </a>
      <span class="c-divider"></span>
      <a
        href="/job_matching/outsources"
        class="c-trigger"
      >
        継続（業務委託）の仕事を探す
        <DNewLabelV2 class="c-newLabel" />
      </a>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import HeaderDropdown from '~/components/organisms/TheHeader/components/HeaderDropdown.vue'
import DNewLabelV2 from '~/components/atoms/DS2/DNewLabelV2'

export default {
  name: 'SearchMenuRequest',
  components: {
    DNewLabelV2,
    HeaderDropdown,
    SearchMenuRequestsLv1: () => import('./SearchMenuRequestsLv1'),
    SearchMenuRequestsLv2: () => import('./SearchMenuRequestsLv2')
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn'])
  }
}
</script>
<style lang="scss" scoped>
.c-container {
  display: inline-flex;
  align-items: center;
}
.c-trigger {
  @include link-effect-background(4px);

  display: inline-block;
  color: $ds2-color-gray-900;
  letter-spacing: 0.02em;
}
.c-divider {
  margin: 0 24px;
  height: 24px;
  border-right: 1px solid $ds2-color-gray-50;
}
</style>
